<template>
  
  <div v-if="record != null" class="questionnaires-wrap scroll-in ">
    <div class="smart-wrap container-in shadowBox " >
      <div class="header smart-wrap">
        <div class="lego col-md-8" >
          <h2 style="font-weight: 600; color: #4a4a4a;">{{record['smartlayout.name']}}</h2>
          <p for="">{{record.item.attributes['questionnaireName'].attributeValue}} ({{record.item.attributes['contact.firstName'].attributeValue}} {{record.item.attributes['contact.lastName'].attributeValue}}  )      </p>
          <span v-if="record.item.attributes.smartFormProgress">
            <div class="progress" :style="'background:'+organizationData.colorSeriesA[1]+'!important;'">
            <div class="progress-bar bg-success" role="progressbar" :style="'width:'+record.item.attributes.smartFormProgress.attributeValue+'%; background:'+organizationData.colorSeriesA[0]+'!important; color:'+organizationData.colorSeriesA[1]+'!important;'" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{{record.item.attributes.smartFormProgress.attributeValue}}%</div>
            </div>
          </span>
        </div>
        <div class="lego col-md-4 absoluteCenterFlex button-q" style="margin:0; padding:0;">
          <ui-button v-if="record.ACLPermits.write == true" style="margin: 0 0 0 10px;" :color="'green'" :text="'Save'" :position="'right'" :id="'save-'+record.item.SK" v-on:click="updateRecord(record)"/>
          <ui-button v-if="record.ACLPermits.write == true"  style="margin: 0 0 0 10px;" :color="'green'" :text="'Send for Review'" :position="'right'" :id="'save-'+record.item.SK" v-on:click="sendReview(record); "/>
        </div>
      </div>
      <paper-layout class="body" :filterSmarForm="filter" :style="record.bodyStyle" v-if="record != null & render" layoutCategory="quiz" id="layout" :layout="record.layout" :data="record.item" :record="record" :models="record.item.attributes" :parent="publicOrganization"/>
    </div>
  </div>
  <ui-modal name="subscription" type="message" >
    <template #content>
      <div style="width: 500px; margin:0 auto; text-align:center; display:block; overflow:hidden;" v-if="subData != null">
        <br>
        <h4>{{subData.updatedBy}} has just updated this record.</h4>
        <h6>If you accept changes, your changes will be lost. <br> Accept changes?</h6>
        <br>
          <p>
            <a data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample" style="color:#373f4A;">
              <strong>Clic For View Details:</strong>
            </a>
          </p>
          <div class="collapse" id="collapseExample">
            <div class="card card-body">
              <p v-for="(att, index) in subData.attNew" :key="index" style="margin: 5px 0 0 0; display:flex; justify-content: space-between; padding:0 10px; border-bottom:1px solid #ccc; text-align: left;" ><span style="font-weight: 500;">{{att[0]}}: </span>  {{ att[1]}}  </p>
            </div>
          </div>
        <ui-button id="getItem" :style="'margin:20px auto 0 auto; width: 200px; background: '+ organizationData.colorSeriesA[0]+';'" :color="'blue'" :text="'Ok'" :position="'center'" data-dismiss="modal" aria-label="Close" v-on:click="getItem()" />
      </div>
    </template>
  </ui-modal>
</template>
<script>
  
  //Tools
  import { API, Storage, Auth} from 'aws-amplify'
  import Vuex from "vuex";
  import Swal from 'sweetalert2'
  import { loading, popUp, processLayout} from '../../store/tools'
  
  //import html2pdf from 'html2pdf'
  //Components
  import PaperDisplayer from '../../components/papers/PaperDisplayer.vue'
  import PaperLayout from '../../components/papers/PaperLayout.vue'
  import UiButton from '../../components/ui/UiButton.vue';
  import UiModal from '../../components/ui/UiModal.vue';
  //GRAPHQL
  import { smd_updateSmartRecord, smd_notify} from '../../graphql/mutations'
  import { smd_getSMLItem, smd_getRecordObj,smd_getSMLItemByName  } from '../../graphql/queries'
  import { onUpdateSmartRecord } from '../../graphql/subscriptions'

  export default {
    name:'checkout',
    components:{
      PaperDisplayer,
      UiButton,
      PaperLayout,
      UiModal,
    },
    data() {
      return {
        record:null,
        response: null,
        subData:null,
        filter:'incomplete',
        render:false,
      } 
    },
    created() {
      this.setPublicOrganization(undefined)
      this.setPublicOrganizationData(undefined)
      console.clear()
      loading('show')
      console.log(this.$router.currentRoute.value.query)
      this.setUserWeb()
      this.subscribe()
      if (this.$router.currentRoute.value.query.filter) {
        this.filter = this.$router.currentRoute.value.query.filter
      }
      //console.log( this.$router.currentRoute.value.query);
      /*if (this.reload == true){&filter=incomplete
        this.setStatusReload(false);
        location.reload();
      }*/
      setTimeout(() => {
        $('#modal-subscription').on('hidden.bs.modal', function (e) {
          var save = new Event('click');
          let buttonSave = document.getElementById('getItem');
          console.log(buttonSave);
          buttonSave.dispatchEvent(save);
        })
      }, 200);
      
    },
    methods: {
      ...Vuex.mapActions(['getPublicOrganization','getPublicOrganizationData']),
      ...Vuex.mapMutations(['setStatusReload','setPublicOrganizationData','setPublicOrgPay','setPublicOrganization','setContactSK','setWebSite','SetUser','SetOrganizationID','setIdentityId']),

      close(){
        window.open(location.href, "_self", "");
        window.close()
      },
      //View
      async setUserWeb(){
        loading('show')
        this.record = null
        if (this.user == "Not authorized" || this.$router.currentRoute.value.query.pass) {
          Auth.signIn(this.$router.currentRoute.value.query.user, this.$router.currentRoute.value.query.pass)
          .then((user) => { 
            this.initSmart(user)
          })
          .catch((err) => popUp(err, 'response', 'err'));
          loading('hidde')
        }else{
          this.getItem();
        }
      },
      async initSmart(user){
        const session = await Auth.currentUserCredentials();
        this.setIdentityId(session.identityId)
        this.SetUser(user.username)
        this.SetOrganizationID(user.attributes["custom:organizationID"]);
        await this.getPublicOrganization()//Alias
        this.getItem();
      },
      async getItem( ) { 
        this.render =false
        loading('show', 'getItem')
        //console.clear()
        let pullData = ''
        try {
          let url = this.$router.currentRoute.value.query
          let query = {
            itemPK: this.organizationID,
            itemSK: this.$router.currentRoute.value.query.SK,
            SMLPK: '#SMARTDASH',
            layoutName: url.formName,
            parent: undefined,
            entity: {
              PK: '#SMARTDASH',
              SK: '#ENT#961aa971-3017-4f13-8f52-0b3d513313a3'
            }
          }
          if (url.formName) {  
            console.log(query);
            pullData = await API.graphql({
              query: smd_getSMLItemByName,
              variables: query
            }); 
            pullData = pullData.data.smd_getSMLItemByName
          }
          else{  
            delete query.layoutName
            if (url.formSK) {
              query.SMLSK = url.formSK
            } else {
              pullData = await API.graphql({
                query: smd_getRecordObj,
                variables:{
                  PK: this.publicOrganization,
                  SK: this.$router.currentRoute.value.query.SK,//data.SK
                }
              })
              query.SMLSK = JSON.parse(pullData.data.smd_getRecordObj.data).SK
            }
            pullData = await API.graphql({
              query: smd_getSMLItem,
              variables: query
            }); 
            
            pullData = pullData.data.smd_getSMLItem
          }
          if (pullData.smd_response.statusCode != 200) {
            popUp(pullData.smd_response, 'response', 'err')
            loading('hidde', 'getItem')
            return
          }
          else if (pullData.smd_response.popAlert == true) {
            popUp(pullData.smd_response, 'response', 'scc')
          }
          console.log(pullData);
          //Proccess Data
          pullData.layout =  processLayout(JSON.parse(pullData.data),'setOrder' , pullData.ACLPermits)
          pullData.item =  JSON.parse(pullData.item)
          pullData.attStr =  JSON.stringify(pullData.item.attributes)
          delete pullData.data
          
          let SML = {}
          let SMLData =JSON.parse(pullData.SML).attributes
          for (const key in SMLData) {
            try {
              if (SMLData[key].attributeType == 'L') {
                SMLData[key].attributeValue =JSON.parse(SMLData[key].attributeValue)
              }
            } catch (error) {
            }
            SML[SMLData[key].attributeName] =SMLData[key].attributeValue
          }
          delete pullData.data
          this.record = Object.assign(pullData, SML) 
          this.setWebSite(this.record) 
          console.log(this.record);
          document.getElementById('title').innerText = this.organizationData['organization.name']+' | '+this.record['smartlayout.name']
          document.getElementById('favicon').href = this.organizationData.favicon[0].url
          document.getElementById('metaTitle').content = this.record['smartlayout.description']
          document.head.insertAdjacentHTML("beforeend", this.record.fontScript)

        } catch (error) {
          console.log(error);
          popUp(error, 'cach', 'err')
        }
        this.render = true
        loading('hidde', 'getItem')
      },
      async updateRecord(data) {  
        console.clear();
        loading('show', 'updateRecord')
        console.log('>> Data Record to Update: ',data);
        //console.log('>> Mode: ',mode);
        let query = { }
        let pushData = ''
        let response = ''

        try {
          //Set Mode and Config
          //asconsole.log(JSON.stringify(processBinds('push',data.item.attributes )));
          query = {
            shortEntity: data.item.shortEntity,
            PK: data.item.PK,
            SK: data.item.SK,
            attributesStr: JSON.stringify(data.item.attributes) ,
            entity: {
              PK: "#SMARTDASH",
              SK: "#ENT#f9910928-2e9d-4e4b-9a43-19fc1ead11a1",
            },
            form: data.item.form,
            parent:  data.item.parent,
            usersCanEdit: data.item.usersCanEdit,
            usersCanRead: data.item.usersCanRead ,
            groupsCanEdit: data.item.groupsCanEdit,
            groupsCanRead: data.item.groupsCanRead,
          }
          if (query.SK == '') {
            query.SK = undefined
          }
          console.log(' >> Push Data <<', query);
          //Mutation
          pushData = await API.graphql({
            query: smd_updateSmartRecord, //smd_updateSmartRecord
            variables: { 
              input:query
            },
          });
          pushData = pushData.data.smd_updateSmartRecord
          //console.log(' >> Successful update <<' ,pushData);
          response = pushData
          pushData = JSON.parse(pushData.data)
          this.record.item = pushData
        } catch (error) {
          popUp(error, 'cach', 'err')
        }
        if (response.smd_response.statusCode != 200) {
          popUp(response.smd_response, 'response', 'err')
          loading('hidde', 'getItem')
          return
        }
        else if (response.smd_response.popAlert == true) {
          popUp(response.smd_response, 'response', 'scc')
        }
        console.log(' >> Successful update <<' ,pushData);
        loading('close','updateRecord')
      },
      async sendReview( record ){
        let pushData = ''
        try {
          pushData = await API.graphql({
            query: smd_notify,
            variables: {
              itemPK: this.publicOrganization,
              itemSK: record.item.SK,
            },
          });
        pushData = pushData.data.smd_notify;
        console.log(pushData);
        Swal.fire({
          icon: 'success',
          title: 'Form Sent. Thank you.',
          text: '',
        })
        } catch (error) {
          console.log(error);
        }
      },
      subscribe(){
        API.graphql({ query: onUpdateSmartRecord }).subscribe({
          next: (eventData) => {
            this.subData = null
            let subData = eventData.value.data.onUpdateSmartRecord;
            subData = JSON.parse(subData.data)
            subData.attNew = []
            let focus = this.record.item
            //console.log('Data of Sub:', subData);
            //console.log('Focus Iten', focus);
            if (subData.PK == this.organizationID ) {
              //Sub in record
              if(subData.SK == focus.SK) {
                if (subData.updatedBy != this.user) {
                  console.log(focus);
                  for (const key in subData.attributes) {
                    try {
                      if (JSON.stringify(subData.attributes[key].attributeValue) != JSON.stringify(focus.attributes[key].attributeValue)) {
                        if (typeof subData.attributes[key].attributeValue != 'string') {
                          subData.attNew.push([subData.attributes[key].label, 'New Item'])
                        } else{
                          subData.attNew.push([subData.attributes[key].label, subData.attributes[key].attributeValue])
                        }
                      }
                    } catch (error) {
                      console.log(error);
                    }
                  }
                  $('#modal-subscription').modal('show')
                }
              }
              this.subData = subData
              /*
              //Notifications
              if (pullData.smd_response.notifyme == true) {
                this.checkPreloadList(this.menu)
              }
              //Alerts
              if (pullData.smd_response.popAlert == true) {
                popUp(false,pullData.smd_response,data)
              }*/
            }
          }
        });
      },
      //Complete
    },
    computed:{
      ...Vuex.mapState(['publicOrganization', 'publicOrganizationData','publicOrganization', 'reload', 'user','publicOrgPay','dataUser','quiz','organizationData','organizationID'])
    }
  }
</script>
<style lang="less">
  #app{
    height: 100vh;
  }
  body{
    height: 100vh;
  }
  .screen-wrap{
    width: 100vw;
    height: 100vh;
    position: fixed;
    background: #00000099;
    display: flex;
    align-items: center;
    overflow: hidden;
    justify-content: center;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    &.show{
      z-index: 999;
      -webkit-transition: all 0.3s ease 0s;
      -moz-transition: all 0.3s ease 0s;
      -ms-transition: all 0.3s ease 0s;
      -o-transition: all 0.3s ease 0s;
      transition: all 0.3s ease 0s;
    }
    .complete{
      width: 400px;
      //height: 400px;
      margin: 0 auto;
      text-align: center;
      padding: 20px;
      border-radius: 10px;
      background: #ffffff;
      display: block;
      border-radius: 10px;
      box-shadow: 0px 0px 13px 4px rgba(0,0,0,0.49);
      -webkit-box-shadow: 0px 0px 13px 4px rgba(0,0,0,0.49);
      -moz-box-shadow: 0px 0px 13px 4px rgba(0,0,0,0.49);
      .ico{
        width: 100%;
        margin: 10px 0;
        svg{
          width: 200px;
          height: 100px;
          display: block;
          margin:0 auto;
        }
      }
      h5{
        margin: 10px 0 40px 0;
      }
      button{
        outline: none;
        cursor: pointer;
        font-weight: 500;
        line-height: inherit;
        position: relative;
        text-decoration: none;
        text-align: center;
        border-style: solid;
        border-width: 1px;
        border-radius: 3px;
        -webkit-appearance: none;
        -moz-appearance: none;

        border: none!important;
        width: 200px;
        background: #22af18;
        padding: 12px;
        color: #fff;
        border-radius: 10px;
        margin: 0 0 5px 0;
        -webkit-transition: all 0.3s ease 0s;
        -moz-transition: all 0.3s ease 0s;
        -ms-transition: all 0.3s ease 0s;
        -o-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
        &:hover{
          background: #238a1c;
          -webkit-transition: all 0.3s ease 0s;
          -moz-transition: all 0.3s ease 0s;
          -ms-transition: all 0.3s ease 0s;
          -o-transition: all 0.3s ease 0s;
          transition: all 0.3s ease 0s;
        }
        &:focus{
          outline: none;
        }
      }
    }
  }
</style>